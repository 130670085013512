
@import url('https://fonts.googleapis.com/css2?family=Jost:wght@300&display=swap');


@media (max-width: 767px) {
  .App {
    align-items: end;
  }
  
}
@media (min-width: 767px) {
  .App {
    align-items: center;
  }

  .impressum
  {  max-width: 50%;}
}

.active
{
  color: black;
  background-color: rgba(255, 255, 255, 0.6);
}

.Home
{
  text-shadow: 1px 1px 2px black;
}


.link
{
  border: 1px solid transparent;
  border-radius: 4px;
  padding: 15px;
  text-decoration: none;
  max-height: 50px;
  margin-left: 10px;
  margin-right: 10px;
}

.link:hover
{
  border: 1px solid white;
}

.impressum
{
  overflow: scroll;

  padding: 25px;
  text-shadow: 1px 1px 2px black;
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.3);
}

.Nav {
  display: flex;  
  flex-direction: row;
  justify-content: center;
  padding: 25px;    
  min-width: 100%;
  background: transparent;
}

.title1{
  text-align: right;
  padding-right: 80px;
  font-size: calc(32px + 2vmin);
}

.title2{
  text-align: right;
  font-size: calc(26px + 2vmin);
}

.App {
  font-family: 'Jost';
  background-color: #b6b3a4;
  min-height: 100vh;
  max-height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  justify-content: right;
  font-size: calc(10px + 2vmin);
  background-image: url(./background2.jpeg);
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: contain;
  color: white;
  padding: 0px;
}

a {
  color: white;
}

html {
  min-width: 100vw;
  min-height: 100vh;
}

body {
  min-width: 100vw;
  min-height: 100vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
